<template>
<section>
  <!-- rehefa eo @ champ vide / à la ligne -->
  <floating-menu :editor="editor" :tippy-options="{ duration: 100 }" v-if="editor">
    <button @click="editor.chain().focus().toggleHeading({ level: 1 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }">
      H1
    </button>
    <button @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }">
      H2
    </button>
    <button @click="editor.chain().focus().toggleBulletList().run()" :class="{ 'is-active': editor.isActive('bulletList') }">
      Bullet List
    </button>
  </floating-menu>
  <!-- rehefa sélectionnena -->
  <bubble-menu :editor="editor" :tippy-options="{ duration: 100 }" v-if="editor">
      <button @click="editor.chain().focus().toggleBold().run()" class="btn btn-primary btn-sm" :class="{ 'is-active': editor.isActive('bold') }">
        <i class="mdi mdi-format-bold "></i>
      </button>
      <button @click="editor.chain().focus().toggleItalic().run()" class="btn btn-primary btn-sm" :class="{ 'is-active': editor.isActive('italic') }">
        <i class="mdi mdi-format-italic "></i>
      </button>
      <button @click="editor.chain().focus().toggleStrike().run()" class="btn btn-primary btn-sm" :class="{ 'is-active': editor.isActive('strike') }">
        <i class="mdi mdi-format-strikethrough"></i>
      </button>
    </bubble-menu>
  <editor-content :editor="editor" />
</section>
</template>

<script>
import { Editor, EditorContent, BubbleMenu, FloatingMenu  } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'

export default {
  name:"tt-editor",
  components: {
    EditorContent,
    BubbleMenu,
    FloatingMenu
  },

  props: {
    value: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      editor: null,
    }
  },

  watch: {
    value(value) {
      // HTML
      const isSame = this.editor.getHTML() === value

      // JSON
      // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

      if (isSame) {
        return
      }

      this.editor.commands.setContent(value, false)
    },
  },

  mounted() {
    this.editor = new Editor({
      content: this.value,
      extensions: [
        StarterKit,
      ],
      onUpdate: () => {
        // HTML
        this.$emit('input', this.editor.getHTML())

        // JSON
        // this.$emit('input', this.editor.getJSON())
      },
    })
  },

  beforeDestroy() {
    this.editor.destroy()
  },
}
</script>
<style lang="scss" scoped>
.ProseMirror {
  > * + * {
    margin-top: 0.75em;
  }
}
</style>